import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import { alpha } from "@mui/material";
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport";
import { POLYGON_RECTANGLE_10_POINTS_LEFT, POLYGON_STAR, } from "../../../utils/clipPathsConstants";
const LazySwipingCaruselWithCards = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: 'LazySwipingCarousel' */ `./SwipingCaruselWithCards`))


const styleSxWrap = {
  my: [ 2, 4, 6, 8 ],
  px: [ 0.5, 1, 2, 3 ],
  py: [ 2, 3, 4, 4, 4 ],
  background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
}


const FullDown = () => {
  const theme = useTheme()
  const bgPrimaryDark = theme.palette.primary2.dark



  return (
    <Container
      component="section"
      sx={styleSxWrap}
    >
      <AnimateScrollIntoViewport
        durationIn={0.5}
        durationOut={0.2}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, backgroundColor: alpha(bgPrimaryDark, 0), }}
        exitFramer={{ clipPath: POLYGON_STAR, backgroundColor: alpha(bgPrimaryDark, 0.8), }}
        styleToMerge={{ clipPath: POLYGON_STAR, backgroundColor: alpha(bgPrimaryDark, 0.8), }}
        name="carousel"
      >
        <Container>
          <Suspense
          // fallback={<div>Loading LazySwipingCarousel ...</div>}
          >
            <LazySwipingCaruselWithCards />
          </Suspense>
        </Container>
      </AnimateScrollIntoViewport>
    </Container>
  );
};

FullDown.propTypes = {
  children: PropTypes.node,
};

export default FullDown;
